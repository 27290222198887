export const checkIsSet = (value) => {
    if (typeof value !== "undefined") {
        return value !== "" &&  Object.keys(value).length > 0;
    } else {
        return typeof value !== "undefined"
    }
}

export const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/tiff",
    "image/webp",
    "video/*"
]

export const fileTypesString = fileTypes.join(", ")

export const whatTypeOfFile = (file) => {
    if (file['type'].includes('image')) return 'image'
    if (file['type'].includes('video')) return 'video'
};