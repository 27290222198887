import React from 'react'
import { InputField } from './InputField'
import { CollapseSection } from '../CollapseSection';

export const InputSection = ({ section, validate, formState, setFormState }) => {
    const sectionFields = section.fields && section.fields.map((f, i) => {
        return <InputField
            key={i}
            field={f}
            validate={validate}
            formState={formState}
            setFormState={setFormState}
        />
    })
    return section.paper ? <CollapseSection section={section} fields={sectionFields}/> : sectionFields;
}