import React, { useState, useEffect } from 'react';
import { InputTextField } from './fields/InputTextField';
import { InputSelect } from './fields/InputSelect';
import { InputAutoComplete } from './fields/InputAutoComplete';
import { InputHidden } from './fields/InputHidden';
import { InputFiles } from './fields/InputFiles';
import { checkIsSet } from '../../utils/common';
import { InputTextFieldWithSlug } from './fields/InputTextFieldWithSlug';
import { InputSwitch } from './fields/InputSwitch';
import { validator } from '../../utils/validator';
import { AdminModel } from '../../modules/admin/models/AdminModel';

export const InputField = ({ field, validate, formState, setFormState }) => {
    const { uploadImage } = AdminModel();
    const { template, current, type, currentCount, validation, imageUrl } = field; 
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);
    const [image, setImage] = useState(imageUrl ? imageUrl : '');
    const [value, setValue] = useState(checkIsSet(current) ? current : '');
    const [first, setFirst] = useState(checkIsSet(current) ? false : true);

    useEffect(() => {
        if (formState.length === 0) {
            setFirst(true);
        }
    }, [formState])

    useEffect(() => {
        if (first && !validate) {
            return
        }
        if (validation) {
            const status = validator(field.name, validation, value, currentCount)
            if (!status.noValidation) {
                setError(status.error)
                setMessage(status.message)
                const formStateCopy = [...formState]
                const exists = formStateCopy.findIndex(fs => fs.field === field.name);
                if (exists === -1) {
                    formStateCopy.push(status)
                } else {
                    formStateCopy[exists] = status
                }
                setFormState(formStateCopy)
            }
        }
    }, [value, currentCount, validate]);

    const handleChange = async (e, val = null, reason = null) => {
        first && setFirst(false)
        if (type === 'file') {
            if (e.target.files[0]) {
                const url = await uploadImage(e.target.files[0])
                setImage(url)
                setValue('')
            }
            // onChangeFile(e)
            // setValue('')
        } else if (type === 'autocomplete') {
            if (reason !== 'input' && Array.isArray(val)) {
                setValue(val)
            }
        } else if (type === 'switch') {
            setValue(e.target.checked)
        } else {
            setValue(e.target.value)
        }
    }

    const newProps = { field: field, value: value, error: error, message: message, handleChange: handleChange }

    switch (template) {
        case 'select':
            return (<InputSelect {...newProps} />)
        case 'autocomplete':
            return (<InputAutoComplete {...newProps} />)
        case 'files':
            return (<InputFiles {...newProps} image={image} />)
        case 'hidden':
            return (<InputHidden {...newProps} />)
        case 'slug':
            return (<InputTextFieldWithSlug {...newProps} />)
        case 'switch':
            return (<InputSwitch {...newProps} />)
        default:
            return (<InputTextField {...newProps} />)
    }
}