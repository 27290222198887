import React from 'react'
import { Button, Typography, Box, SvgIcon } from '@mui/material'
import { icons } from '../../../../components/Icons'

export const PageActions = ({ title, actions, items }) => {

    const handleClick = async (action, func) => {
        func && await func(items)
    }

    return (
        <Box
            component="header"
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 3
            }}
        >
            <Typography component="h1" variant="h5" sx={{ m: 0, p: 0 }}>{title}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {actions && actions.map((a, i) => {
                    if (a.show) {
                        return (
                            <Button
                                onClick={() => handleClick(a.action, a.func)}
                                size='small'
                                variant='outlined'
                                color={a.color}
                                key={i}
                                sx={{ ml: 1 }}
                            >
                                {<SvgIcon component={icons[a.icon]} sx={{ mr: 1 }} />} {a.label}
                            </Button>
                        )
                    }
                    return false
                })}
            </Box>
        </Box>
    )
}
