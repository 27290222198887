import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './NavAnchor.scss';

const NavAnchor = ({link, extraClass}) => {
  return (
    <li className='nav-anchor'>
      <HashLink smooth to={`/${link.link}`} className={`nav-anchor__link ${extraClass}`}>
        {link.title}
      </HashLink>
    </li>
  )
}

export default NavAnchor