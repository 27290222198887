import { IconButton, SvgIcon } from '@mui/material'
import styled from '@emotion/styled';

export const AnimatedIconButton = styled((props) => {
    const { expand, icon, rotation, rotationDirection, ...other } = props;
    return <IconButton {...other}>
        <SvgIcon component={icon}/>
    </IconButton>;
})(({ theme, expand, rotation, rotationDirection }) => ({
    transform: !expand ? 'rotate(0deg)' : `rotate(${rotationDirection ? rotationDirection : ''}${rotation}deg)`,
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));