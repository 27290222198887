import React from 'react'
import NoMatchPage from '../../components/NoMatchPage/NoMatchPage'
import { Outlet, Navigate} from 'react-router-dom'
import { useAuth } from "../../hooks/useAuth"
import { Toasts } from "../../components/Toasts";
import { LoginPage } from './views/Login';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useTheme } from "../../hooks/useTheme";

export default function AuthModule() {
    const { user } = useAuth();
    const { pcwTheme } = useTheme();

    if (user) {
        return <Navigate to="/admin" />;
    }

    return (
        <ThemeProvider theme={pcwTheme}>
            <CssBaseline />
            <Outlet />
            <Toasts />
        </ThemeProvider>
    );
}

export const AuthRoutes = {
    element: <AuthModule />,
    errorElement: <NoMatchPage />,
    path: "/auth",
    children: [
        {
            element: <LoginPage />,
            path: "/auth"
        }
    ]
}