import { checkIsSet } from "./common";

export const validator = (name, validations, value, currentCount = false) => {
    let validated = { error: false, message: '', field: name };
    validations.every(validation => {
        if (validation.noValidation) {
            validated = { noValidation: true, error: false }
            return false
        }
        if (validation.required && methods.checkRequired(value)) {
            validated = { error: true, message: validation.message, field: name }
            return false;
        }
        if (validation.minLength && methods.checkMinLength(value, validation.minLength)) {
            validated = { error: true, message: validation.message, field: name }
            return false
        }
        if (validation.exists && methods.checkExists(value, validation.exists.prev, validation.exists.attr, validation.exists.editing)) {
            validated = { error: true, message: validation.message, field: name }
            return false
        }
        if (validation.email && methods.checkEmail(value)) {
            validated = { error: true, message: validation.message, field: name }
            return false
        }
        if (validation.select && methods.checkSelect(value, validation.select.value)) {
            validated = { error: true, message: validation.message, field: name }
            return false
        }
        if (validation.maxLength && methods.checkMaxLength(value, validation.maxLength)) {
            validated = { error: true, message: validation.message, field: name }
            return false
        }
        if (validation.filesCount && methods.checkFilesCount(currentCount, validation.filesCount.min, validation.filesCount.max)) {
            validated = { error: true, message: validation.message, field: name }
            return false
        }
        if (validation.slug && methods.checkSlug(value)) {
            validated = { error: true, message: validation.message, field: name }
            return false
        }
        return true
    })
    return validated
}

const methods = {
    checkRequired: (val) => {
        return val.length > 1 ? false : true;
    },

    checkMinLength: (val, length, useCount = false, currentCount = false) => {
        const check = !useCount ? val.length : currentCount
        return check >= length ? false : true;
    },

    checkMaxLength: (val, length, useCount = false, currentCount = false) => {
        const check = !useCount ? val.length : currentCount
        return check < length ? false : true;
    },

    checkExists: (val, existing, attribute, editing) => {
        if (val === editing) {
            return false;
        } else {
            return [...existing].find(ex => {
                return attribute.split('.').reduce((prev, current) => {
                    const check = prev[current];
                    if (typeof check !== 'undefined' && typeof check === 'string') {
                        return val.toLowerCase() === check.toLowerCase() ? true : false
                    } else {
                        return !checkIsSet(prev) ? ex[current] : prev[current]
                    }
                }, {})
            })
        }
    },

    checkEmail: (val) => {
        return /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(val) ? false : true
    },

    checkSelect: (val, check) => {
        return val !== check ? false : true;
    },

    checkSlug: (val) => {
        const regex = "^[a-zA-Z0-9-]*$";
        return !val.match(regex);
    },

    checkFilesCount: (count, min, max) => {
        return count >= min && count <= max ? false : true;
    },
}   