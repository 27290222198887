import { createContext, useContext, useState, useCallback } from "react";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState({});

    const setNotification = useCallback((notification) => {
        setNotifications(notification);
    },
        [notifications, setNotifications]
    );

    const clearNotification = useCallback(() => {
        setNotifications({});
    },
        [notifications, setNotifications]
    );

    const value = {
        notifications,
        setNotification,
        clearNotification
    }

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export function useNotification() {
    return useContext(NotificationContext);
}
