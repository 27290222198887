import { Collapse, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { AnimatedIconButton } from './AnimatedIconButton';
import { icons } from './Icons';

export const CollapseSection = ({ section, fields }) => {
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Paper elevation={2} sx={{ p: 1, mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {section.label && <Typography variant="h6" gutterBottom sx={{ mx: 1 }}>{section.label}</Typography>}
                {/* <AnimatedIconButton
                    expand={expanded}
                    onClick={handleExpandClick}
                    icon={icons.expandMore}
                    rotation={180}
                    aria-expanded={expanded}
                    aria-label="show more"
                /> */}
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {fields}
            </Collapse>
        </Paper>
    )
}
