import { createContext, useEffect, useContext, useMemo, useState } from "react";
import { auth } from "../config/firebase";
import { browserSessionPersistence, setPersistence, onAuthStateChanged, signOut, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useNotification } from "./useNotification";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setNotification } = useNotification();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const login = async (data) => {
    setLoading(prev => !prev)
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, data.email, data.password)
          .then((userCredential) => {
            setLoading(prev => !prev)
            setNotification({ message: 'Success, logged in.', variant: 'success' })
          })
          .catch((error) => {
            setLoading(prev => !prev)
            setNotification(createErrorMessage(error.code))
          });
      })
      .catch((error) => {
        setLoading(prev => !prev)
        setNotification(createErrorMessage(error.code))
      });
  };

  const reset = async (data) => {
    setLoading(prev => !prev)
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        setNotification({ message: 'Sent email to associated address. Please follow those instructions', variant: 'success' })
        setLoading(prev => !prev)
      })
      .catch((error) => {
        setLoading(prev => !prev)
        setNotification(createErrorMessage(error.code))
      });
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        setNotification({ message: 'Successfully logged out', variant: 'success' })
      })
      .catch((error) => {
        setNotification(createErrorMessage(error.code))
      })

  };

  const createErrorMessage = (error) => {
    let message = {
      message: 'problem with request',
      variant: 'error'
    };
    if (error === "auth/invalid-email") {
      message.message = "no user exists"
    }
    if (error === "auth/wrong-password") {
      message.message = "password is incorrect"
    }
    if (error === "auth/app-not-authorized") {
      message.message = "not authorized"
    }
    if (error === "auth/requires-recent-login") {
      message.message = "requires recent login"
    }
    if (error === "auth/user-not-found") {
      message.message = "user not found"
    }
    if (error === "auth/email-already-in-use") {
      message.message = "user already exists"
    }
    if (error === "auth/weak-password") {
      message.message = "weak password, minimum 6 characters"
    }
    if (error === "auth/too-many-requests") {
      message.message = "too many requests, try back later"
    }
    if (error === "auth/insufficient-permission") {
      message.message = "insufficient permissions contact administrator"
    }
    return message
  }

  const value = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
      reset
    }),
    [user, loading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth.user != null}
};