import React from 'react'
import { ListItemIcon, ListItemText, SvgIcon, MenuItem } from "@mui/material";
import { icons } from '../../../../components/Icons';

export const TableListItemAction = ({ id, orig, setAnchorEl, action }) => {
    const handleActionClick = async (action, func) => {
        if (action === 'edit') {
            await func(id)
        }
        if (action === 'delete') {
            await func(id, orig);
        }
        if (action === 'duplicate') {
            await func(id, orig);
        }
        setAnchorEl(null);
    };

    return (
        <MenuItem dense onClick={() => handleActionClick(action.action, action.func)}>
            <ListItemIcon>
                <SvgIcon component={icons[action.icon]} color={action.color} />
            </ListItemIcon>
            <ListItemText>{action.label}</ListItemText>
        </MenuItem>
    )
}