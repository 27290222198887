import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuDrawer } from './MenuDrawer';
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from '@mui/material';

export const MenuAppBar = ({ sections, language }) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <Box component="nav" sx={{ flexGrow: 1 }}>
            {typeof sections !== 'undefined' && sections.length > 0 && <MenuDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} sections={sections} language={language} />}
            <AppBar position="static">
                <Toolbar>
                    {typeof sections !== 'undefined' && sections.length > 0 &&
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => setOpenDrawer(prev => !prev)}
                        >
                            <MenuIcon />
                        </IconButton>
                    }
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <Link to="/admin" underline="none" color="inherit" component={RouterLink}>
                            {language.site.name}
                        </Link>
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}