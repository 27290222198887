import React from 'react';
import { frontEndLinks } from '../Nav/data.js';
import NavAnchor from '../NavAnchor';
import './NavSide.scss';

function NavSide({animating, handleClick}) {
    return (
        <div className={`nav-side ${animating ? 'nav-side--slide-out' : 'nav-side--slide-in'}`}>
            <ul className='nav-side__list'  onClick={handleClick}>
                {frontEndLinks.map((link, index) => <NavAnchor extraClass="nav-side__list-item" link={link} key={index} />)}
            </ul>
        </div>
    )
}

export default NavSide