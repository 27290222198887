import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { icons } from '../../../components/Icons';
import { useLanguage } from "../../../hooks/useLanguage";
import { useTheme } from "../../../hooks/useTheme";

export const SectionsModel = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { language, chooseLanguage, otherLanguage } = useLanguage();
    const { views, areas } = language.admin;
    const { setTheme, theme } = useTheme();

    const handleLanguage = () => {
        chooseLanguage(otherLanguage);
        navigate('/admin')
    }

    const handleNavigateHome = () => {
        navigate('/')
    }

    const sections = [
        {
            label: areas.content.label,
            path: "admin",
            views: [
                { label: views.homepage.nav, path: "homepage", icon: icons.home },
                { label: views.equipment.nav, path: "equipment", icon: icons.equipment },
                { label: views.contact.nav, path: "contact", icon: icons.email },
                { label: views.settings.nav, path: "settings", icon: icons.settings }
            ]
        },
        {
            label: areas.actions.label,
            path: "admin",
            views: [
                { label: theme ? language.buttons.lightMode.label : language.buttons.darkMode.label, path: "", icon: theme ? icons.lightMode : icons.darkMode, action: () => setTheme(prev => !prev) },
                { label: language.buttons.language.label, path: '', icon: icons.language, action: handleLanguage },
                { label: language.buttons.viewHomepage.label, path: '', icon: icons.viewHomepage, action: handleNavigateHome },
                { label: language.buttons.logout.label, path: '', icon: icons.logout, action: logout }
            ]
        }
    ];

    return { sections, language }
}
