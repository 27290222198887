export const getFields = (page, language) => {
    const data = page;
    const { fields, sections } = language;
    return [
        {
            label: sections.details,
            paper: true,
            fields: [
                {
                    name: 'banner',
                    label: fields.banner.title,
                    type: 'text',
                    current: data.banner ? data.banner : '',
                    validation: [{ required: true, message: fields.banner.required }]
                }, {
                    name: 'body',
                    label: fields.body.title,
                    type: 'text',
                    current: data.body ? data.body : '',
                    rows: 12,
                    multiline: true,
                    helpText: fields.body.helpText,
                    validation: [{ required: true, message: fields.body.required }]
                }
            ],
        },
        {
            label: sections.meta,
            paper: true,
            fields: [
                {
                    name: 'meta_title',
                    label: fields.metaTitle.title,
                    type: 'text',
                    current: data.meta_title ? data.meta_title : '',
                    validation: [{ noValidation: true }]
                },
                {
                    name: 'meta_desc',
                    label: fields.metaDesc.title,
                    type: 'text',
                    current: data.meta_desc ? data.meta_desc : '',
                    rows: 4,
                    multiline: true,
                    validation: [{ noValidation: true }]
                }
            ],
        }
    ]
}