import { db, storage } from "../../../config/firebase";
import { doc, getDoc, collection, getDocs, query, where, updateDoc, writeBatch, addDoc, deleteDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Resizer from "react-image-file-resizer";

export const AdminModel = () => {
    const getHome = async () => {
        const page = await getOne('pages', 'home');
        return page
    }

    const getOne = async (type, id) => {
        const docRef = doc(db, type, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data()
            return { id: id, data: data }
        } else {
            return {}
        }
    }

    const getAll = async (type) => {
        const final = [];
        const querySnapshot = await getDocs(collection(db, type));
        querySnapshot.forEach((doc) => {
            final.push({ id: doc.id, data: doc.data() })
        });
        const sorted = final.sort((a, b) => (b.data.sort < a.data.sort) ? 1 : -1)
        return sorted;
    }

    const getOneByParam = async (type, property, propertyName) => {
        const q = query(collection(db, type), where(propertyName, "==", property));
        const querySnapshot = await getDocs(q);
        let response = null;
        querySnapshot.forEach((doc) => {
            console.log(doc)
            response = doc.data()
        });
        return response
    }

    const editOne = async (type, id, data) => {
        const docRef = doc(db, type, id);
        try {
            await updateDoc(docRef, data);
            return { message: 'Success', variant: 'success' }
        } catch (err) {
            return { message: 'Error try again', variant: 'error' }
        }
    }

    const addOne = async (type, data) => {
        return await addDoc(collection(db, type), data);
    }

    const deleteOne = async (type, id) => {
        return await deleteDoc(doc(db, type, id));
    }

    const saveSort = async (type, data) => {
        const batch = writeBatch(db);
        [...data].forEach(d => {
            const docRef = doc(db, type, d.id);
            batch.update(docRef, d.data);
        })
        return await batch.commit();
    }

    const resizeFile = (file, height, width) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(file, height, width, "JPEG", 70, 0, (uri) => {
                resolve(uri);
            }, "file", height, width);
        });
    }

    const uploadImage = (file) => {
        return new Promise(async (resolve, reject) => {
            try {
                const resizedImage = await resizeFile(file, 600, 600)
                uploadBytes(ref(storage, `/equipment/${file.name}`), resizedImage).then((snapshot) => {                    
                    getDownloadURL(snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL)
                    });
                });
            } catch (err) {
                console.log(err)
                reject(false)
            }
        });
    }
    
    return {
        getAll,
        getOne,
        getOneByParam,
        getHome,
        editOne,
        deleteOne,
        saveSort,
        addOne,
        uploadImage
    }
}