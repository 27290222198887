import React from 'react'
import { Box, FormControl, TextField } from '@mui/material'
import { InputHelperText } from '../InputHelperText';

export const InputTextFieldWithSlug = ({ field, error, handleChange, value, message }) => {
    const { name, label, type, autoComplete, autoFocus, disabled, inputProps, helpText, slug } = field;

    const createSlugValue = (val) => {
        return val
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');
    }

    return (
        <Box sx={{display: "flex", flexDirection: "row", gap: 2, mx: 1}}>
            <FormControl sx={{ mb: 1, mt: 1, width: 1 }}>
                <TextField
                    id={name}
                    label={label}
                    name={name}
                    type={type}
                    value={value}
                    onChange={handleChange}
                    onSelect={handleChange}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    error={error}
                    disabled={disabled}
                    inputProps={inputProps}
                    size="small"
                />
                <InputHelperText error={error} message={message} helpText={helpText} />
            </FormControl>
            <FormControl sx={{ mb: 1, mt: 1, width: 1 }}>
                <TextField
                    id={slug.name}
                    label={slug.label}
                    name={slug.name}
                    type={slug.type}
                    value={createSlugValue(value)}
                    disabled={disabled}
                    inputProps={{ readOnly: true }}
                    size="small"
                />
                <InputHelperText helpText={slug.helpText} />
            </FormControl>
        </Box>
    )
}
