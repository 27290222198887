import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAuth } from "../../../../hooks/useAuth";
import { ListSubheader, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const MenuDrawerList = ({ sections, setOpenDrawer, language }) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawer(prev => !prev);
    };

    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
        >
            <List dense>
                {sections && sections.map((section, index) => (
                    <React.Fragment key={index}>
                        <ListSubheader key={index}>
                            {section.label}
                        </ListSubheader>

                        {section.views.map((p, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton onClick={
                                    p.action ? p.action : () => navigate(`/${section.path}/${p.path}`)
                                }>
                                    <ListItemIcon>
                                        <SvgIcon component={p.icon} />
                                    </ListItemIcon>
                                    <ListItemText primary={p.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider />
                    </React.Fragment>
                ))}
                <ListSubheader sx={{ mb: -2, backgroundColor: "transparent" }}>
                    {language.admin.areas.loggedInAs.label}
                </ListSubheader>
                <ListItem disablePadding>
                    <ListItemButton disabled>
                        <ListItemText primaryTypographyProps={{ fontSize: "0.75rem" }} primary={user.email} />
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    );
}