import React from 'react'
import { FormControl, TextField } from '@mui/material'
import { InputHelperText } from '../InputHelperText';

export const InputTextField = ({ field, error, handleChange, value, message }) => {
    const { name, label, type, autoComplete, autoFocus, disabled, inputProps, helpText, rows, multiline, width, margin, size } = field;
    return (
        <FormControl sx={{ mb: 1, mt: 1, width: width ? width : 1 }}>
            <TextField
                id={name}
                label={label}
                name={name}
                type={type}
                value={value}
                onChange={handleChange}
                onSelect={handleChange}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                error={error}
                disabled={disabled}
                inputProps={inputProps}
                rows={rows}
                multiline={multiline}
                sx={{mx: margin == 0 || margin ? margin : 1} }
                size={size ? size : 'small'}
            />
            <InputHelperText error={error} message={message} helpText={helpText} />
        </FormControl>
    )
}
