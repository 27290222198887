import { useCallback } from "react";
import { useNotification } from "./useNotification"
import { useLanguage } from "./useLanguage";

export const useFunction = () => {
    const { setNotification } = useNotification();
    const { language } = useLanguage();
    const { messages } = language;

    const runDatabaseFunction = useCallback(async (dbFunction, success, error) => {
        try {
            const response  = await dbFunction();
            success && setNotification({message: messages[success], variant: 'success'})
            return response
        }  catch (err) {
            console.log(err)
            error && setNotification({message: messages[error], variant: 'error'})
        }
    })
    
    return { runDatabaseFunction }
}