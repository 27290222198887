import React from 'react'
import { MainSection } from '../components/layout/MainSection'

export default function Dashboard() {
  const info = { title: "Dashboard"}
  return (
    <MainSection {...info}>
      Welcome to your site, please use the navigation menu on the title bar to access various settings. More information will be provided on this page in the future.
    </MainSection>
  )
}
