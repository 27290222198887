import React from 'react';
import './Cta.scss';
import { FiPhoneCall } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { FiMapPin } from "react-icons/fi";


function Cta({ phone, facebook, map }) {
  return (
    <section className='cta'>
        <a href={`tel:${phone}`} className='cta__ghost'>
          <div className='cta__action'>
            <FiPhoneCall className='cta__icon' />
            <h2 className='cta__heading'>Call Us</h2>
          </div>
        </a>
        <a href={map} target='_blank' rel="noreferrer" className='cta__ghost'>
          <div className='cta__action'>
            <FiMapPin className='cta__icon' />
            <h2 className='cta__heading'>Find Us</h2>
          </div>
        </a>
        <a href={facebook} target="_blank" rel="noreferrer" className='cta__ghost'>
          <div className='cta__action'>
            <FiFacebook className='cta__icon' />
            <h2 className='cta__heading'>Facebook</h2>
          </div>
        </a>
    </section>
  )
}

export default Cta