import { useAuth } from "../../../hooks/useAuth";
import { BasicPage } from "../../../components/BasicPage";
import { InputForm } from "../../../components/form/InputForm";
import { icons } from "../../../components/Icons";
import { useLanguage } from "../../../hooks/useLanguage";

export const LoginPage = () => {
  const { login, loading } = useAuth();
  const { language } = useLanguage();
  const { fields, auth, buttons } = language;

  const sections = [
    {
      paper: false,
      fields: [
        {
          name: 'email',
          label: fields.email.title,
          type: 'email',
          autoComplete: 'email',
          autoFocus: false,
          validation: [
            {
              required: true,
              message: language.fields.email.required
            },
            {
              email: true,
              message: language.fields.email.email
            }
          ],
          margin: 0,
          size: 'large'
        },
        {
          name: 'password',
          label: fields.password.title,
          type: 'password',
          autoComplete: 'current-password',
          autoFocus: false,
          validation: [
            {
              required: true,
              message: language.fields.password.required
            },
            {
              minLength: 8,
              message: language.fields.password.minLength
            }
          ],
          margin: 0,
          size: 'large'
        }
      ]
    }
  ]

  return (
    <BasicPage title={auth.views.login} icon={<icons.login />}>
      <InputForm sections={sections} action={login} buttonText={buttons.login.label} loading={loading} />
    </BasicPage>
  );
};