import { Suspense } from 'react';
import { useLanguage } from '../../../hooks/useLanguage';
import { useLoaderData, Await, useNavigate } from 'react-router-dom';
import { EditView } from '../components/edit/EditView';
import { AdminModel } from '../models/AdminModel';
import { getFields } from '../models/SettingsFields';
import { useFunction } from '../../../hooks/useFunction';
import Loading from '../components/loading/Loading';

export default function Settings() {
  const { editOne } = AdminModel();
  const { language } = useLanguage();
  const { loader } = useLoaderData();
  const { runDatabaseFunction} = useFunction();
  const navigate = useNavigate();

  const edit = (page) => {
    const info = {
      title: language.admin.views.settings.edit,
      pageActions: [],
      users: [],
      sections: getFields(page.data, language),
      action: async (formData) => await runDatabaseFunction(() => editOne('site', 'company', formData), 'successEdit', 'failedEdit'),
      additionalAction: () => navigate('/admin'),
      buttonText: language.buttons.save.label,
      loading: false,
      fullWidth: true,
    }
    return <EditView info={info} />
  }

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={loader}>
        {(page) => edit(page)}
      </Await>
    </Suspense>
  )
}
