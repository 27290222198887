import React, { useState, useCallback, useEffect } from 'react'
import { List, Paper, Typography } from '@mui/material'
import { TableListItem } from './TableListItem'

export const TableList = ({ titleKey, listItems, tableActions, setSortButton, verbose }) => {
    const [tableListItems, setTableListItems] = useState()
    useEffect(() => {
        setTableListItems(listItems)
    }, [listItems])

    const moveTableListItem = useCallback((dragIndex, hoverIndex) => {
        setSortButton(true)
        setTableListItems((prev) => {
            const ex = [...prev]
            ex[dragIndex] = [ex[hoverIndex], ex[hoverIndex] = ex[dragIndex]][0]
            return [...ex].map((e, i) => {
                e.data.sort = i;
                return e;
            });
        })
    }, [])

    const renderTableListItem = useCallback((listItem, index, orig) => {
        return (
            <TableListItem
                titleKey={titleKey}
                key={index}
                orig={orig}
                index={index}
                last={orig.length === index + 1 ? true : false}
                actions={tableActions}
                id={listItem.id}
                item={listItem.data}
                moveTableListItem={moveTableListItem}
                verbose={verbose}
            />
        )
    }, [])

    return (
        tableListItems && tableListItems.length > 0 &&
        <Paper elevation={2} sx={{ px: 0, mt: 1 }}>
            <List sx={{ py: 0 }}>
                {tableListItems.map((item, i, orig) => renderTableListItem(item, i, orig))}
            </List>
        </Paper>
    )
}
