import { db, storage } from "../../../config/firebase";
import { doc, getDoc, collection, getDocs, query, where, addDoc } from "firebase/firestore";
import { getDownloadURL, ref} from "firebase/storage";

export const FrontEndModel = () => {
    const equipRef = 'equipment';
    const pagesRef = 'pages';
    const siteRef = 'site';

    const refs = {
        'equipment': equipRef,
        'page': pagesRef,
        'site': siteRef
    }

    const getSite = async () => {
        const docRef = doc(db, refs.site, 'company');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data()
        } else {
            return {}
        }
    }

    const getHome = async () => {
        const equipment = await getAll('equipment');
        const page = await getOne('page', 'home');
        return { ...page, equipment: equipment }
    }

    const getOne = async (type, id) => {
        const site = await getSite();
        const docRef = doc(db, refs[type], id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { ...docSnap.data(), ...site }
        } else {
            return {}
        }

    }

    const getAll = async (type) => {
        const final = [];
        const querySnapshot = await getDocs(collection(db, type));
        querySnapshot.forEach((doc) => {
            final.push({ id: doc.id, ...doc.data() })
        });
        const sorted = final.sort((a, b) => (b.sort < a.sort) ? 1 : -1)
        return sorted;
    }

    const getOneByParam = async (type, property, propertyName) => {
        const site = await getSite();
        const q = query(collection(db, refs[type]), where(propertyName, "==", property));
        const querySnapshot = await getDocs(q);
        let response = null;
        querySnapshot.forEach((doc) => {
            response = doc.data()
        });
        return { ...response, ...site }
    }

    const sendContactForm = async (data) => {
        return await addDoc(collection(db, "contact"), data)
    }

    return {
        getAll,
        getOne,
        getOneByParam,
        getHome,
        sendContactForm
    }
}