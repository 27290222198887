import React from 'react'
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

export default function Loading() {
  return (
    <Box sx={{mt:3}}>
        <Skeleton variant="rectangular" width={150} height={40} sx={{ mt:3, mb:1}}/>
        <Skeleton variant="rectangular" width="100%" height={40} sx={{mb:1}}/>
        <Skeleton variant="rectangular" width="100%" height={40} sx={{mb:1}}/>
        <Skeleton variant="rectangular" width="100%" height={40} sx={{mb:1}}/>
        <Skeleton variant="rectangular" width="100%" height={40} sx={{mb:1}}/>
        <Skeleton variant="rectangular" width="100%" height={40} sx={{mb:1}}/>
        <Skeleton variant="rectangular" width="100%" height={40} sx={{mb:1}}/>
      </Box>
  )
}
