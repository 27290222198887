import React from 'react';
import './Button.scss';

function Button({type, text, clickHandler, alt}) {
  if (alt) {
    return (
      <button type={type} className={`button button--${alt}`} onClick={clickHandler}>{text}</button>
    )
  }
  return (
    <button type={type} className={`button ${alt && 'button--submit'}`} onClick={clickHandler}>{text}</button>
  )
}

export default Button