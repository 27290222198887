import React from "react";
import { MainSection } from "../layout/MainSection";
import { InputForm } from "../../../../components/form/InputForm";

export const AddView = ({info}) => {    
    return (
        <MainSection {...info}>
            <InputForm {...info}/>
        </MainSection>
    )
};