import React, { useState, Suspense } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Hero from '../../components/Layout/Hero';
import Loading from '../../components/Layout/Loading';
import Nav from '../../components/Layout/Nav';
import Footer from '../../components/Layout/Footer';
import CardList from '../../components/CardList';
import Banner from '../../components/Banner';
import parse from 'html-react-parser';
import './HomePage.scss';
import { Await, useLoaderData } from 'react-router-dom';
import NoMatchPage from '../../../../components/NoMatchPage/NoMatchPage';

const PageLayout = (page) => {
    const [formDetails, setFormDetails] = useState(null);

    if (!page.body) {
        return <NoMatchPage errorSent="404" />
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page.name} | Home`}</title>
                <meta name='description' content={`${page.meta_desc}`} />
            </Helmet>
            <Nav name={page.name} />
            <main className='main'>
                <Hero phone={page.phone} map={page.map} facebook={page.facebook} />
                <section className='home' id='welcome'>
                    <div className='home__container'>
                        <div className='home__narrow'>
                            <h1 className='home__heading'>Welcome</h1>
                            {parse(page.body)}
                        </div>
                    </div>
                </section>
                <Banner banner={page.banner} />
                {page.equipment &&
                    <section className='home' id='equipment'>
                        <div className='home__container'>
                            <h2 className='home__heading'>Equipment</h2>
                            <CardList equipment={page.equipment} setFormDetails={setFormDetails} />
                        </div>
                    </section>
                }
            </main>
            <Footer {...page} formDetails={formDetails} />
        </HelmetProvider>
    )
}

const HomePage = () => {
    const { loader } = useLoaderData();
    return (
        <Suspense fallback={<Loading />}>
            <Await resolve={loader}>
                {(page) => PageLayout(page)}
            </Await>
        </Suspense>
    )
}

export default HomePage