import { createContext, useContext, useState, useCallback } from "react";
import english from '../lang/en.json';
import french from '../lang/fr.json';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(english);
    const [otherLanguage, setOtherLanguage] = useState('fr');

    const chooseLanguage = useCallback((chosen) => {
        if (chosen === 'fr') {
            setLanguage(french)
            setOtherLanguage('en')
        } else {
            setLanguage(english)
            setOtherLanguage('fr')
        }
    },
        [language, setLanguage]
    );

    const value = {
        language,
        otherLanguage,
        chooseLanguage,
    }

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export function useLanguage() {
    return useContext(LanguageContext);
}
