import React from 'react';
import { PageActions } from './PageActions';
import { Box } from '@mui/system';

export const MainSection = ({ children, title, listItems, pageActions }) => {
    return (
        <>
            <PageActions
                title={title}
                items={listItems}
                actions={pageActions}
            />
            <Box component="section" pt={3}>
                {children}
            </Box>
        </>
    )
}
