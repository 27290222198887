export const getFields = (page, language) => {
    const data = page;
    const { fields, sections } = language;
    return [
        {
            label: sections.details,
            paper: true,
            fields: [
                {
                    name: 'model',
                    label: fields.model.title,
                    type: 'text',
                    current: data.model ? data.model : '',
                    validation: [{ required: true, message: fields.model.required }]
                },
                {
                    name: 'status',
                    label: fields.status.title,
                    type: 'text',
                    current: data.status ? data.status : '',
                    validation: [{ required: true, message: fields.status.required }]
                },
                {
                    name: 'desc',
                    label: fields.desc.title,
                    type: 'text',
                    current: data.desc ? data.desc : '',
                    rows: 6,
                    multiline: true,
                    validation: [{ noValidation: true }]
                }
            ],
        },
        {
            label: sections.image,
            paper: true,
            fields: [
                {
                    name: 'image',
                    label: fields.image.title,
                    template: 'files',
                    type: 'file',
                    imageUrl: data.image ? data.image : '',
                    validation: [{ noValidation: true }]
                }
            ],
        }
    ]
}