import React from 'react';
import './Banner.scss';

function Banner({banner}) {
  return (
    <section className='banner'>
        <div className='banner__container'>
            <p className='banner__heading'>{banner}</p>
        </div>
    </section>
  )
}

export default Banner