import { PhotoCamera } from '@mui/icons-material';
import { FormControl, Button, Box } from '@mui/material'
import React from 'react'
import { InputHelperText } from '../InputHelperText';

export const InputFiles = ({ field, error, handleChange, value, message, image }) => {
    const { name, helpText, width, margin, size, current } = field;
    console.log(image, value)
    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1rem", width: width ? width : 1 }}>
            <FormControl sx={{ width: "100%" }}>
                <Button
                    variant="outlined"
                    component="label"
                    color={error ? 'error' : 'inherit'}
                    sx={{ py: "0.8rem", mx: margin == 0 || margin ? margin : 1 }}
                    size={size ? size : 'small'}
                >
                    <PhotoCamera sx={{ mr: 1 }} /> Choose Image
                    <input
                        hidden
                        accept="image/*"
                        value={value}
                        onChange={handleChange}
                        type="file"
                    />
                </Button>
                <input hidden name={name} value={image} onChange={() => console.log('uploaded')} />
                <InputHelperText error={error} message={message} helpText={helpText} />
            </FormControl>
            <Box sx={{ width: "70%" }}>
                {image && image !== '' && <img src={image} style={{ width: "100%" }} />}
            </Box>
        </Box>

    )
}
