import React, { useState, useEffect } from "react";
import { useLanguage } from "../../hooks/useLanguage";
import { useNotification } from "../../hooks/useNotification";
import { Box } from "@mui/material";
import { InputSection } from "./InputSection";
import { LoadingActionButton } from "./LoadingActionButton";
import { icons } from "../Icons";

export const InputForm = ({ sections, action, customAction, additionalAction, additionalData, buttonText, loading, fullWidth }) => {
    const { setNotification } = useNotification();
    const { language } = useLanguage();
    const { messages } = language;
    const [formState, setFormState] = useState([]);
    const [validate, setValidate] = useState(false);
    const [formData, setFormData] = useState(null);
    const width = fullWidth ? 'xl' : 'sm';
    const fields = sections.flatMap(s => s.fields);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData(createFormData(e.currentTarget));
    };

    useEffect(() => {
        if (formData !== null) {
            setValidate(true)
        }
    }, [formData]);

    useEffect(() => {
        const trySubmit = async () => {
            const valid = formState.findIndex(f => f.error === true) === -1 && formState.length !== 0
            if (valid) {
                setFormState([]);
                if (additionalData) {
                    await action({
                        ...formData,
                        ...additionalData
                    });
                } else {
                    await action(formData);
                }
                if (additionalAction) {
                    await additionalAction(formData)
                }
            } else {
                setNotification({message: messages.formError, variant: 'error'})
            }
        }
        if (validate) {
            trySubmit()
        }
        return () => {
            setValidate(false)
            setFormData(null)
        };
    }, [validate])

    const createFormData = (form) => {
        const data = new FormData(form);
        const fieldValues = {}
        fields && fields.forEach(f => {
            if (f.slug) {
                fieldValues[f.slug.name] = data.get(f.slug.name)
            }
            fieldValues[f.name] = data.get(f.name)
        });
        return fieldValues;
    }

    return (
        <Box
            component="form"
            onSubmit={customAction ? customAction : handleSubmit}
            noValidate
            autoComplete="on"
            maxWidth={width}
            width="100%"
        >
            {sections && sections.map((section, i) => {
               return <InputSection key={i} section={section} validate={validate} formState={formState} setFormState={setFormState} />
            })}
            <LoadingActionButton type="submit" loading={loading} text={buttonText} icon={icons.send} />
        </Box>
    );
};
