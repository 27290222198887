import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { frontEndLinks } from './data.js';
import NavAnchor from '../NavAnchor';
import './Nav.scss';
import { FiMenu } from "react-icons/fi";
import NavSide from '../NavSide/NavSide.js';

const Nav = ({name}) => {
  const [ showSideNav, setShowSideNav ] = useState(false); 
  const [ animating, setAnimating ] = useState(true);
  
  const handleClick = () => {
    if (!showSideNav) {
      setShowSideNav(prev => !prev);
      setTimeout(()=>{
        setAnimating(prev => !prev);
      }, 400)
    } else {
      setAnimating(prev => !prev);
      setTimeout(()=>{
        setShowSideNav(prev => !prev);
      }, 600)
    }
  }

  return (
    <>
      <nav className='nav'>
        <div className='nav__body'>
          <Link className='nav__home-link' to="/">
            {name}
          </Link>
          <ul className='nav__list'>
            {frontEndLinks.map((link, index) => <NavAnchor link={link} key={index} />)}
          </ul>
          <div className='nav__menu-link' onClick={handleClick}><FiMenu /></div>
        </div>
      </nav>
      {showSideNav && <NavSide animating={animating} handleClick={handleClick} />}
    </>
  )
}

export default Nav