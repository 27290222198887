import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import Button from '../Button';
import ReCAPTCHA from "react-google-recaptcha";
import './Forms.scss';
import { FrontEndModel } from '../../models/FrontEndModel';

function Form({formDetails}) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [success, setSuccess] = useState(false);
    const [newMessage, setNewMessage] = useState(false);
    const contactForm = useRef(null);

    useEffect(() => {
        setValue("message", formDetails);
        formDetails && formDetails !== '' && setNewMessage(true);
        formDetails && formDetails !== '' && contactForm.current?.scrollIntoView({ behavior: "smooth" })
    }, [formDetails, setValue]);
    
    const recaptchaRef = React.createRef();

    const onSubmit = async (data) => {
        recaptchaRef.current.executeAsync()
            .then(res => {
                FrontEndModel().sendContactForm(data)
                    .then(res => {
                        setSuccess(true);
                    })
                    .catch(err => console.log(err))
            });
    }

    const message = () => {
        return <section className='form__submitted'>
            Thank you for your submission, we will contact you shortly.
        </section>
    }

    const form = () => {
        return <form className={`form ${newMessage && newMessage !== '' ? 'form--has-message' : ''}`} onSubmit={handleSubmit(onSubmit)} ref={contactForm}>
            <input
                type="text"
                className={`form__input ${errors.name && 'form__input--has-error'}`}
                name="name"
                placeholder="name"
                {...register("name", {
                    required: true,
                    minLength: 3,
                    pattern: ""
                })} />
            {errors.name && <span className='form__error'>* Required and min 3 characters</span>}
            <input
                type="text"
                className={`form__input ${errors.email && 'form__input--has-error'}`}
                name="email"
                placeholder="email"
                {...register("email", {
                    required: "Required field",
                    pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format"
                    }
                })} />
            {errors.email && <span className='form__error'>* {errors.email.message}</span>}
            <input
                type="text"
                className={`form__input ${errors.phone && 'form__input--has-error'}`}
                name="phone"
                placeholder="phone"
                {...register("phone", {
                    required: true,
                    pattern: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
                })} />
            {errors.phone && <span className='form__error'>* Required proper phone number</span>}
            <textarea
                className={`form__textarea ${errors.message && 'form__textarea--has-error'}`}
                name="body"
                placeholder="message"
                rows="4"
                {...register("message", { required: true, minLength: 10, pattern: "" })}></textarea>
            {errors.message && <span className='form__error'>* Required and min 10 characters</span>}
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LfgeYQiAAAAAASXueYSCnvHFgp8SJVrruM1MAeL"
            />
            <Button type="submit" text="send" />
        </form>
    }

    return !success ? form() : message();
}

export default Form