import { createBrowserRouter } from "react-router-dom";
import App from '../App';
import { FrontEndRoutes } from "../modules/frontend/FrontEndModule";
import NoMatchPage from "../components/NoMatchPage/NoMatchPage";
import { AdminRoutes } from "../modules/admin/AdminModule";
import { AuthRoutes } from "../modules/auth/AuthModule";

export const AppRouter = createBrowserRouter([
    {
        element: <App />,
        children: [
            FrontEndRoutes,
            AdminRoutes,
            AuthRoutes
        ],
        errorElement: <NoMatchPage />
    },
]);