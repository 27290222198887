import { Suspense, useState } from 'react';
import { useLanguage } from '../../../hooks/useLanguage';
import { useLoaderData, Await, useNavigate } from 'react-router-dom';
import { EditView } from '../components/edit/EditView';
import { AddView } from '../components/add/AddView';
import { TableView } from '../components/table/TableView';
import { AdminModel } from '../models/AdminModel';
import { getFields } from '../models/EquipmentFields';
import { useFunction } from '../../../hooks/useFunction';
import Loading from '../components/loading/Loading';

export default function Contact() {
  const navigate = useNavigate();
  const { editOne, addOne, deleteOne, saveSort } = AdminModel();
  const { language } = useLanguage();
  const { loader } = useLoaderData();
  const { runDatabaseFunction } = useFunction();
  const [sortButton, setSortButton] = useState(false);

  const Table = (contact) => {
    const [local, setLocal] = useState(contact);

    const performDelete = async (type, id) => {
      await deleteOne(type, id)
      setLocal(prev => [...prev].filter(p => p.id !== id));
    }

    const info = {
      title: language.admin.views.contact.table,
      titleKey: "email",
      listItems: local,
      loading: false,
      verbose: true,
      setSortButton: setSortButton,
      tableActions: [
        {
          ...language.buttons.delete,
          func: async (id) => await runDatabaseFunction(() => performDelete('contact', id), 'successDelete', 'failedDelete')
        }
      ],
      pageActions: [
        {
          ...language.buttons.sort,
          show: sortButton,
          func: async (data) => await runDatabaseFunction(() => saveSort('contact', data), 'successSorted', 'failedSorted')
        }
      ]
    }
    return <TableView info={info} />
  }

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={loader}>
        {(contact) => {
            return Table(contact)
        }}
      </Await>
    </Suspense>
  )
}
