import * as React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

export const InputSwitch = ({ field, value, handleChange, message }) => {
    const { label, width, name, margin } = field;

    return (
        <FormControlLabel sx={{ width: width ? width : 1 }}
            control={
                <Switch
                    name={name}
                    checked={value === '' ? false : value}
                    onChange={handleChange}
                    value={value === '' ? false : value}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{ mx: margin == 0 || margin ? margin : 1 }}
                />
            }
            label={label}
        />
    );
}