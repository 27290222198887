export const getFields = (page, language) => {
    const data = page;
    const { fields, sections } = language;
    return [
        {
            label: sections.details,
            paper: true,
            fields: [
                {
                    name: 'name',
                    label: fields.name.title,
                    type: 'text',
                    current: data.name ? data.name : '',
                    validation: [{ required: true, message: fields.name.required }]
                },
                {
                    name: 'about',
                    label: fields.about.title,
                    type: 'text',
                    current: data.about ? data.about : '',
                    rows: 5,
                    multiline: true,
                    helpText: fields.about.helpText,
                    validation: [{ required: true, message: fields.about.required }]
                }
            ],
        },
        {
            label: sections.address,
            paper: true,
            fields: [
                {
                    name: 'address',
                    label: fields.address.title,
                    type: 'text',
                    current: data.address ? data.address : '',
                    validation: [{ required: true, message: fields.address.required }]
                },
                {
                    name: 'city',
                    label: fields.city.title,
                    type: 'text',
                    current: data.city ? data.city : '',
                    validation: [{ required: true, message: fields.city.required }]
                },
                {
                    name: 'province',
                    label: fields.province.title,
                    type: 'text',
                    current: data.province ? data.province : '',
                    validation: [{ required: true, message: fields.province.required }]
                },
                {
                    name: 'postal',
                    label: fields.postal.title,
                    type: 'text',
                    current: data.postal ? data.postal : '',
                    validation: [{ required: true, message: fields.postal.required }]
                },
                {
                    name: 'phone',
                    label: fields.phone.title,
                    type: 'text',
                    current: data.phone ? data.phone : '',
                    validation: [{ required: true, message: fields.phone.required }]
                },
            ],
        },
        {
            label: sections.additional,
            paper: true,
            fields: [
                {
                    name: 'map',
                    label: fields.map.title,
                    type: 'text',
                    current: data.map ? data.map : '',
                    validation: [{ noValidation: true }],
                    helpText: fields.map.helpText
                },
                {
                    name: 'email',
                    label: fields.email.title,
                    type: 'text',
                    current: data.email ? data.email : '',
                    validation: [{ required: true, message: fields.email.required }]
                },
                {
                    name: 'facebook',
                    label: fields.facebook.title,
                    type: 'text',
                    current: data.facebook ? data.facebook : '',
                    validation: [{ noValidation: true }],
                    helpText: fields.facebook.helpText
                },
                {
                    name: 'weekday_hours',
                    label: fields.weekday_hours.title,
                    type: 'text',
                    current: data.weekday_hours ? data.weekday_hours : '',
                    validation: [{ noValidation: true }],
                },
                {
                    name: 'weekend_hours',
                    label: fields.weekend_hours.title,
                    type: 'text',
                    current: data.weekend_hours ? data.weekend_hours : '',
                    validation: [{ noValidation: true }],
                }
            ],
        }
    ]
}