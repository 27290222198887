import React from 'react'
import NoMatchPage from '../../components/NoMatchPage/NoMatchPage'
import { defer, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth"
import { Toasts } from "../../components/Toasts";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useTheme } from "../../hooks/useTheme";
import { MenuAppBar } from './components/menu/MenuAppBar'
import { SectionsModel } from './models/SectionsModel';
import Dashboard from './views/Dashboard';
import Page from './views/Page';
import Equipment from './views/Equipment';
import Settings from './views/Settings';
import { AdminModel } from './models/AdminModel';
import { Box, Container } from '@mui/system';
import Contact from './views/Contact';

export default function AdminModule() {
    const { user } = useAuth();
    const { pcwTheme } = useTheme();
    const { sections, language } = SectionsModel();

    if (!user) {
        return <Navigate to="/auth" />;
    }

    return (
        <ThemeProvider theme={pcwTheme}>
            <CssBaseline />
            <MenuAppBar sections={sections} language={language} />
            <Box component="main" sx={{ mb: 5 }}>
                <Container component="section">
                    <Outlet />
                </Container>
            </Box>
            <Toasts />
        </ThemeProvider>
    );
}

export const AdminRoutes = {
    element: <AdminModule />,
    errorElement: <NoMatchPage />,
    path: "/admin",
    children: [
        {
            element: <Dashboard />,
            path: "/admin",
        },
        {
            element: <Page />,
            path: "homepage",
            loader: () => {
                const loader = AdminModel().getHome();
                return defer({
                    loader
                });
            }
        },
        {
            element: <Equipment action="table" />,
            path: 'equipment',
            loader: () => {
                const loader = AdminModel().getAll('equipment')
                return defer({
                    loader
                });
            }
        },
        {
            element: <Equipment action="add" />,
            path: "equipment/add",
            loader: () => {
                const loader = AdminModel().getAll('equipment')
                return defer({
                    loader
                });
            }
        },
        {
            element: <Equipment action="edit" />,
            path: "equipment/edit/:id",
            loader: ({ params }) => {
                const loader = AdminModel().getOne('equipment', params.id)
                return defer({
                    loader
                });
            }
        },
        {
            element: <Contact/>,
            path: 'contact',
            loader: () => {
                const loader = AdminModel().getAll('contact')
                return defer({
                    loader
                });
            }
        },
        {
            element: <Settings />,
            path: "settings",
            loader: () => {
                const loader = AdminModel().getOne('site', 'company');
                return defer({
                    loader
                });
            }
        }
    ]
}