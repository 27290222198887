import React from 'react'
import { FormHelperText } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

export const InputHelperText = ({ error, message, helpText }) => {
    return (
        <FormHelperText error={error} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {error && <HelpOutlineOutlinedIcon sx={{ fontSize: 14, mr: "0.25rem", mb: "0" }} />}
            {helpText && !error && <ErrorOutlineOutlinedIcon sx={{ fontSize: 14, mr: "0.25rem", mb: "0" }} />}
            {error ? message : helpText}
        </FormHelperText>
    )
}
