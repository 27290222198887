import React from 'react';
import './Hero.scss';
import logo from '../../../assets/logo/logo_trans_bg.png';
import Cta from '../../Cta';

function Hero({ phone, map, facebook }) {
  return (
    <section className='hero'>
      <div className='hero__container'>
        <img className='hero__logo' src={logo} alt="redline equipment rentals logo" />
        <Cta phone={phone} map={map} facebook={facebook} />
      </div>
    </section>
  )
}

export default Hero