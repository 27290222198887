import { createContext, useContext, useState, useMemo } from "react";
import { createTheme } from '@mui/material/styles'

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(false);

    const pcwTheme = createTheme({
        palette: {
            mode: !theme ? 'light' : 'dark',
            primary: { main: !theme ? "#6e7e85" : "#777" }
        },
    });
    
    const value = useMemo(
        () => ({
            setTheme,
            pcwTheme,
            theme
        }),
        [theme, pcwTheme]
      );

    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export function useTheme() {
    return useContext(ThemeContext);
}
