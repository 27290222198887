import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import AutoStoriesSharpIcon from '@mui/icons-material/AutoStoriesSharp';
import StyleSharpIcon from '@mui/icons-material/StyleSharp';
import PhotoCameraSharpIcon from '@mui/icons-material/PhotoCameraSharp';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import AbcIcon from '@mui/icons-material/Abc';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockResetIcon from '@mui/icons-material/LockReset';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import DragIndicatorSharpIcon from '@mui/icons-material/DragIndicatorSharp';
import SortSharpIcon from '@mui/icons-material/SortSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import PrecisionManufacturingSharpIcon from '@mui/icons-material/PrecisionManufacturingSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';

const icons = {
    home: HomeSharpIcon,
    equipment: PrecisionManufacturingSharpIcon,
    pages: AutoStoriesSharpIcon,
    collections: ContentCopySharpIcon,
    categories: StyleSharpIcon,
    assets: PhotoCameraSharpIcon,
    globals: LanguageSharpIcon,
    users: PeopleSharpIcon,
    settings: SettingsSharpIcon,
    language: AbcIcon,
    viewHomepage: RemoveRedEyeSharpIcon,
    logout: ExitToAppIcon,
    login: LockOutlinedIcon,
    resetPassword: LockResetIcon,
    add: AddCircleSharpIcon,
    edit: EditSharpIcon,
    delete: ClearSharpIcon,
    copy: ContentCopySharpIcon,
    drag: DragIndicatorSharpIcon,
    sort: SortSharpIcon,
    moreHorizontal: MoreHorizSharpIcon,
    moreVertical: MoreVertSharpIcon,
    send: SendSharpIcon,
    lightMode: Brightness7Icon,
    darkMode: Brightness4Icon,
    expandMore: ExpandMoreSharpIcon,
    email: EmailSharpIcon
}

export { icons };