import React, {useState, useEffect} from 'react';
import Button from '../Button/Button';
import './Card.scss';

function Card({ card, setFormDetails }) {

    const clickHandler = (e, card) => {
        e.preventDefault();
        setFormDetails(`Hi, I am interested in the ${card.model} with a status of ${card.status}. Please contact me thanks.`);
    }

    return (
        <div className='card'>
            <div className='card__header'>
                <h3 className='card__heading'>{card.status}</h3>
            </div>
            <div className='card__body'>
                <img className='card__image' src={card.image} alt="equipment is here" />
                <p className='card__equip'>
                    {card.model}
                </p>
                {card.desc &&
                    <p className='card__desc'>
                        {card.desc}
                    </p>
                }
            </div>
            <div className='card__footer'>
                <Button text="send request" clickHandler={(e) => clickHandler(e, card)} />
            </div>
        </div>
    )
}

export default Card