import React from 'react';
import Card from '../Card/Card';
import './CardList.scss';

function CardList({equipment, setFormDetails}) {
  return (
    <section className='card-list'>
      {equipment.map((card, index) => <Card key={index} card={card} setFormDetails={setFormDetails} />)}
    </section>
  )
}

export default CardList