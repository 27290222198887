import React from 'react';
import './Loading.scss';

const Loading = () => {
    return (
        <section className='loading'>
            <div className='loading__ring'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}

export default Loading