import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useNotification } from '../hooks/useNotification';

export const Toasts = () => {
    const { notifications, clearNotification } = useNotification();
    const notif = () => {
        return <Snackbar open={true} autoHideDuration={8000} onClose={() => clearNotification()}>
            <Alert onClose={() => clearNotification()} severity={notifications.variant} sx={{ width: '100%' }}>
                {notifications.message}
            </Alert>
        </Snackbar>;
    }

    return notifications.message ? notif() : '';
}