import React from 'react'
import HomePage from './pages/HomePage/HomePage'
import NoMatchPage from '../../components/NoMatchPage/NoMatchPage'
import { defer, Outlet } from 'react-router-dom'
import { FrontEndModel } from './models/FrontEndModel'

export default function FrontEndModule() {
    return <Outlet />
}

export const FrontEndRoutes = {
    element: <FrontEndModule />,
    errorElement: <NoMatchPage />,
    children: [
        {
            element: <HomePage />,
            path: "/",
            loader: () => {
                const loader = FrontEndModel().getHome();
                return defer({
                    loader
                });
            }
        },
        {
            element: <HomePage />,
            path: "/:slug",
            loader: ({ params }) => {
                const loader = FrontEndModel().getOneByParam('page', params.slug, 'slug');
                return defer({
                    loader
                });
            }
        },
    ]
}