import React from 'react';
import Form from '../../Form/Form';
import './Footer.scss';

function Footer({ about, hours, phone, email, address, city, province, postal, map, weekday_hours, weekend_hours, formDetails }) {
    return (
        <footer className='footer' id="contact">
            <div className='footer__container'>
                <div className='footer__third'>
                    <h2 className='footer__heading'>About</h2>
                    <p>
                        {about}
                    </p>
                    <h3>Business Hours</h3>
                    <div className='footer__hours'>
                    {weekday_hours && weekday_hours !== '' &&
                        <div className='footer__hour'>
                           Weekday Hours
                        <div>{weekday_hours}</div>
                        </div>
                    }
                    {weekend_hours && weekend_hours !== '' &&
                        <div className='footer__hour'>
                           Weekday Hours
                        <div>{weekend_hours}</div>
                        </div>
                    }
                    </div>
                </div>
                <div className='footer__third'>
                    <h2 className='footer__heading'>Contact</h2>
                    <h3 className='footer__subheading'>
                        Phone
                    </h3>
                    <p>
                        <a className='footer__link' href={`tel:${phone}`}>{phone}</a>
                    </p>
                    <h3 className='footer__subheading'>
                        Email
                        <br />
                    </h3>
                    <p>
                        <a className='footer__link' href={`mailto:${email}`}>{email}</a>
                    </p>
                    <h3 className='footer__subheading'>
                        Address
                    </h3>
                    <p>
                        <a className='footer__link' href={map}>
                            {address}<br />
                            {city}, {province}<br />
                            {postal}
                        </a>
                    </p>

                </div>
                <div className='footer__third footer__third--last' id="form">
                    <h2 className='footer__heading'>Form</h2>
                    <Form formDetails={formDetails} />
                    <div className='footer__privacy'>
                        This site is protected by reCAPTCHA and the Google&nbsp;
                        <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;and&nbsp;
                        <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp;apply.
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer