import { Suspense, useState } from 'react';
import { useLanguage } from '../../../hooks/useLanguage';
import { useLoaderData, Await, useNavigate } from 'react-router-dom';
import { EditView } from '../components/edit/EditView';
import { AddView } from '../components/add/AddView';
import { TableView } from '../components/table/TableView';
import { AdminModel } from '../models/AdminModel';
import { getFields } from '../models/EquipmentFields';
import { useFunction } from '../../../hooks/useFunction';
import Loading from '../components/loading/Loading';

export default function Equipment({ action }) {
  const navigate = useNavigate();
  const { editOne, addOne, deleteOne, saveSort } = AdminModel();
  const { language } = useLanguage();
  const { loader } = useLoaderData();
  const { runDatabaseFunction } = useFunction();
  const [sortButton, setSortButton] = useState(false);
  
  const Edit = (equip) => {
    const info = {
      title: language.admin.views.equipment.edit,
      pageActions: [
        { ...language.buttons.cancel, show: true, func: () => navigate('/admin/equipment') }
      ],
      sections: getFields(equip.data, language),
      action: async (formData) => await runDatabaseFunction(() => editOne('equipment', equip.id, formData), 'successEdit', 'failedEdit'),
      additionalAction: () => navigate('/admin/equipment'),
      buttonText: language.buttons.save.label,
      loading: false,
      fullWidth: true,
    }
    return <EditView info={info} />
  }

  const Add = (equip) => {
    const info = {
      title: language.admin.views.equipment.add,
      pageActions: [
        { ...language.buttons.cancel, show: true, func: () => navigate('/admin/equipment') }
      ],
      sections: getFields(equip, language),
      action: async (formData) => await runDatabaseFunction(() => addOne('equipment', formData), 'successEdit', 'failedEdit'),
      additionalAction: () => navigate('/admin/equipment'),
      buttonText: language.buttons.save.label,
      additionalData: { sort: equip.length + 1 },
      currentCount: equip.length + 1,
      loading: false,
      fullWidth: true,
    }
    return <AddView info={info} />
  }

  const Table = (equip) => {
    const [localEquip, setLocalEquip] = useState(equip);

    const performDelete = async (type, id) => {
      await deleteOne(type, id)
      setLocalEquip(prev => [...prev].filter(p => p.id !== id));
    }

    const info = {
      title: language.admin.views.equipment.table,
      titleKey: "model",
      listItems: localEquip,
      loading: false,
      setSortButton: setSortButton,
      tableActions: [
        {
          ...language.buttons.edit,
          func: (id) => navigate('/admin/equipment/edit/' + id)
        },
        {
          ...language.buttons.delete,
          func: async (id) => await runDatabaseFunction(() => performDelete('equipment', id), 'successDelete', 'failedDelete')
        }
      ],
      pageActions: [
        {
          ...language.buttons.sort,
          show: sortButton,
          func: async (data) => await runDatabaseFunction(() => saveSort('equipment', data), 'successSorted', 'failedSorted')
        },
        {
          ...language.buttons.add,
          show: true,
          func: () => navigate('/admin/equipment/add')
        }
      ]
    }
    return <TableView info={info} />
  }

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={loader}>
        {(equip) => {
          if (action === 'table') {
            return Table(equip)
          }
          if (action === 'edit') {
            return Edit(equip)
          }
          if (action === 'add') {
            return Add(equip)
          }
        }}
      </Await>
    </Suspense>
  )
}
