import React from "react";
import { MainSection } from "../layout/MainSection";
import { TableList } from "./TableList";

export const TableView = ({info}) => {
  return (
    <MainSection {...info}>
      <TableList {...info} />
    </MainSection>
  );
};