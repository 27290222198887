import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Loading } from "./Loading";

export const BasicPage = ({ title, icon, loading, children }) => {
  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>{icon}</Avatar>
        <Typography sx={{ mb: 3 }} component="h1" variant="h5">
          {title}
        </Typography>
      {children}
      </Box>
      <Loading loading={loading} />
    </Container>
  );
};
