import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { AppRouter } from "./routes/AppRouter";
import { AuthProvider } from './hooks/useAuth';
import { NotificationProvider } from "./hooks/useNotification";
import { LanguageProvider } from "./hooks/useLanguage";
import { ThemeProvider } from "./hooks/useTheme";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <DndProvider backend={HTML5Backend}>
      <NotificationProvider>
        <LanguageProvider>
          <ThemeProvider>
            <AuthProvider>
              <RouterProvider router={AppRouter} />
            </AuthProvider>
          </ThemeProvider>
        </LanguageProvider>
      </NotificationProvider>
    </DndProvider>
  </StrictMode>
);