import React, { useState, useRef } from 'react'
import { ListItem, ListItemIcon, ListItemText, SvgIcon, Menu, Divider } from "@mui/material";
import { icons } from '../../../../components/Icons';
import { TableListItemAction } from './TableListItemAction';
import { useDrag, useDrop } from 'react-dnd'
import { AnimatedIconButton } from '../../../../components/AnimatedIconButton';

export const TableListItem = ({titleKey, item, orig, id, last, index, actions, moveTableListItem, verbose}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ref = useRef(null)

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [{ handlerId }, drop] = useDrop({
        accept: 'li',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveTableListItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })
    const [{ opacity }, drag] = useDrag({
        type: 'li',
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1
        }),
    })

    return (
        <ListItem divider={!last} disableGutters ref={drag(drop(ref))} style={{ opacity }} data-handler-id={handlerId}>
            <ListItemIcon>
                <SvgIcon component={icons.drag} sx={{ cursor: 'grab' }} />
                <Divider orientation="vertical" flexItem={true} />
            </ListItemIcon>
            {verbose ?
                Object.values(item).map((it, i) => <ListItemText key={i} primary={it} />)
                :
                <ListItemText primary={item[titleKey]} />
            }
            <AnimatedIconButton
                    expand={open}
                    onClick={handleMenuClick}
                    icon={icons.moreHorizontal}
                    rotation={90}
                    rotationDirection="-"
                    aria-expanded={open}
                    aria-label="show more"
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
            >
                {actions && actions.map((a, i) => <TableListItemAction key={i} orig={orig} action={a} id={id} setAnchorEl={setAnchorEl} />)}
            </Menu>
        </ListItem>
    )
}
