export const frontEndLinks = [
    {
        title: 'Home',
        link: '#top',
    },
    {
        title: 'Welcome',
        link: '#welcome',
    },
    {
        title: 'Equipment',
        link: '#equipment',
    },
    {
        title: 'Contact',
        link: '#contact',
    },
]